import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import MainBanner from "../components/Common/PageBanner.js";
import { graphql, StaticQuery } from "gatsby"
import { Col, Container, Row } from "react-bootstrap"
import KurznewsCard from "../components/kurznews/KurznewsCard"
import Seo from "../components/App/Seo"

const KurznewsArchiv = ({data, pageContext}) => {
  return (
    <Layout>
      <Navbar/>
      <Seo title={"Das Kurznewsarchiv von LOGENTIS"}
           description={"Alle Kurznews von LOGENTIS auf einer Seite. " +
           "Erfahre als Erster was es bei LOGENTIS neues gibt. "}
           image={"/images/og/logentis-main-og.png"}
      />
      <MainBanner pageTitle={"Kurznewsarchiv"}
                  homePageUrl={"/"}
                  homePageText={"Home"}
                  activePageText={"Kurznewsarchiv"}
                  bannerClassname={"page-title-area-smalltopspacing"}
                  withCallToAction={false}/>
      <section className="services-area pt-100 pb-70 bg-f1f8fb">
        <Container>
          <Row>
            {data.allKurznewsJson.nodes.map((news) => (
              <Col key={news.id} lg={4} sm={6} className={"mt-4"}>
                <KurznewsCard news={news}/>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
      <Footer/>
    </Layout>
  );
};

const Main = () => (
  <StaticQuery
    query={graphql`
      query KurznewsarchivQuery {
        allKurznewsJson (
          sort: { fields: [date], order: DESC }
        ) {
            nodes {
                id
                date(formatString: "DD.MM.YYYY")
                tags
                news
                title
                moreinfolink
                moreinfotext
            }
        }
      }
    `}
    render={(data) => <KurznewsArchiv data={data} />}
  />
)

export default Main;
